
import PDEButton from '@/components/pde/PDEButton.vue'
import { mapActions } from 'vuex'

export default {
  components: { PDEButton },
  emits: ['loadView'],

  methods: {
    ...mapActions('navigation', ['navigateToApplication']),

    handleNavigateToApplication() {
      this.$emit('loadView', 'applicationIsOpenInAnotherTab')
      this.navigateToApplication()
    },
  },
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-120" }
const _hoisted_2 = { class: "text-center py-20 leading-10 text-24 text-pureblack-65" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('account.application.prepop.error.globalDisabled.promptMessage1')), 1),
      _createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('account.application.prepop.error.globalDisabled.promptMessage2')) + " ", 1),
        _createElementVNode("a", {
          class: "inlineLink",
          href: "#",
          "data-test": "prompt-cta-link",
          onClick: _cache[0] || (_cache[0] = (...args) => ($options.handleNavigateToApplication && $options.handleNavigateToApplication(...args)))
        }, _toDisplayString(_ctx.$t('account.application.prepop.error.globalDisabled.promptCta')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('account.application.prepop.error.globalDisabled.promptMessage3')), 1)
      ])
    ])
  ]))
}
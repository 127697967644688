
import PDEButton from '@/components/pde/PDEButton.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { PDEButton },
  emits: ['prefillTryAgain', 'loadView'],

  computed: {
    ...mapGetters('account', ['division']),
  },

  methods: {
    ...mapActions('navigation', ['navigateToApplication']),

    handleNavigateToApplication() {
      this.$emit('loadView', 'applicationIsOpenInAnotherTab')
      this.navigateToApplication()
    },
  },
}

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "spinner-svg" }
const _hoisted_2 = { class: "prefill-loading-message" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "application-spinner text-primary-500" }, null, -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.loadingMessage), 1)
  ]))
}
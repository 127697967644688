
import PDEButton from '@/components/pde/PDEButton.vue'
import PDESvg from '@/components/pde/PDESvg.vue'

import { mapActions } from 'vuex'

export default {
  components: { PDEButton, PDESvg },
  methods: {
    ...mapActions('navigation', ['navigateToApplication']),
  },
}

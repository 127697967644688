

import PDEButton from '@/components/pde/PDEButton.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmApplicationPrepop',

  components: { PDEButton },
  emits: ['loadView'],

  methods: {
    ...mapActions('navigation', ['navigateToApplication']),
    ...mapActions('prefill', ['prepopOptIn']),

    // No Answer
    async handleNavigateToApplication() {
      this.$emit('loadView', 'applicationIsOpenInAnotherTab')
      this.navigateToApplication()
      await this.prepopOptIn(false)
    },

    // Yes Answer
    async handleNavigateToAppPrepop() {
      this.$emit('loadView', 'prepopForm')
      await this.prepopOptIn(true)
    },
  },
}
